import { useEffect, useState} from 'react'
import Modal from "react-bootstrap/Modal";
import {Container,Row,Col} from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { useLocation} from 'react-router-dom';
import { bonuscontract, chain, moralis } from './App';
import { centralwallet } from './App';
import { ethers } from 'ethers';
import { tokenaddress, marketplacecontract } from './App';
import marketplaceabi from './marketabi.json' 

const abi = require('erc-20-abi')
const {ethereum} = window

export default function Bonus(images) {
    const [show,setshow] = useState(false)
    const [data, setdata] = useState()
    const [owner, setowner]=useState('')
    const [id, setid]=useState()
    const [arr, setarr]=useState([])
    const [obj, setobj]=useState()
    const [keys, setkeys] = useState([])
    const { pathname }= useLocation();
    const [title , settitle] = useState()
    const [des , setdes] = useState()
    const navigate = useNavigate();

    const handleClose=()=>{setshow(false)}

    const buy =async () => {
      if(ethereum){
        var accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      }
      else
        window.location.href='https://metamask.io/download.html'
      if(accounts){
        console.log(id)
        let provider = new ethers.providers.Web3Provider(ethereum);
        let signer = provider.getSigner();
        let tokenContract = new ethers.Contract(tokenaddress, abi, signer);
        let allow = await tokenContract.allowance(accounts[0], marketplacecontract)
        console.log(ethers.utils.formatEther(allow.toString()))
        if(parseInt(ethers.utils.formatEther(allow.toString())) < 100000){
          let tx = await tokenContract.approve(marketplacecontract, ethers.utils.parseUnits("10000000", "ether"))
          await tx.wait()
        }
        let marketContract = new ethers.Contract(marketplacecontract, marketplaceabi, signer);
        await marketContract.buyUSDC(id);
      }
      // window.open(links[id]) 
    }

    const sold = (items) => {
      for(let i = 0 ; i < items.length; i ++){
          if(items[i]['owner_of'] != centralwallet){
            return items[i]
          }
      }
      return items[0]
    }

    useEffect(()=>{ 
        const options1 = {
            method: 'GET',
            url: `https://deep-index.moralis.io/api/v2/nft/${bonuscontract}/owners`,
            params: {chain: chain, format: 'decimal'},
            headers: {accept: 'application/json', 'X-API-Key': moralis}
          };

          
        axios
        .request(options1)
        .then((response)=>{
            console.log(response.data.result[0])
            console.log(response.data.result.map(item =>JSON.parse(item['metadata'])))
            setarr(response.data.result)
        })

    },[])

    const showpopup = (key) =>{
        // setdes(JSON.parse(arr[key]['metadata'])['description'])
        // settitle(JSON.parse(arr[key]['metadata'])['name'])
        // setid(parseInt(arr[key]['token_id']))
        // setowner(arr[key]['owner_of'])
        // setdata(arr[key])
        // setshow(true)
    }

    const go = (key)=>{
      navigate(`${keys[key].replace(/\s/g,'')}`)
      console.log(pathname + "/"+ keys[key].replace(/\s/g, ''))
      console.log(keys, images)
      keys.map(item=>console.log(`${item.replace(/\s/g,'')}`))
    }

    const back = () => {
        navigate("/");
    }

     return(
        <div className="App-header1">
            <h1 className="h1-color">{images ? images.title:""}</h1>
            <br/>
            <div className='button' onClick={back}>back</div>

            <div className='d-flex justify-content-center flex-wrap'>        
            {arr.length == 0 ?
                <div className="">No countries</div>:
            arr.map((image, key)=><div>
            <div className='imagecard' key={key}>
            {
                    image?<div>
                      <img className='image' onClick={e=>{showpopup(key);}} src= {
                      JSON.parse(image['metadata'])['image'].includes('pinata')
                      ?JSON.parse(image['metadata'])['image'].replace('gateway.pinata', 'be1.mypinata')
                      :"https://ipfs.io/ipfs/" + JSON.parse(image['metadata'])['image'].slice(7)
                      }></img>
                      <div className='d-flex flex-wrap'>
                        <div className='round'>
                           {/* {JSON.parse(image['metadata'])['attributes']['0']['value']} */}
                        </div>
                        <div className='round'>
                           {/* {Math.pow(3, parseInt(JSON.parse(image['metadata'])['attributes'][0]['value'])-1) } */}
                        </div>
                        <div className='round'>
                           {owner.toUpperCase() == centralwallet.toUpperCase()?"S":"N"}
                        </div>
                        <div onClick={e=>go(key)} className='roundedbutton'>
                           +
                        </div>
                      </div>
                      </div>:
                    <img className='image'></img>
                  }
              
            </div>
          </div>)
            }
            </div>
            <Modal 
              dialogClassName ="modala"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                  { data?
                        JSON.parse(data['metadata'])['attributes'].map(item=>
                        <Row>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['trait_type']}
                            </div>
                            </Col>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['value']}
                            </div>
                            </Col>
                        </Row>
                      ):""}
                       <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Description
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {des}
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Owner
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {owner}
                      </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Id
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {id}
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      {
                        owner.toUpperCase() == centralwallet.toUpperCase()?
                        <button class="btn btn-outline-primary btn-md btn-block buttonbox" onClick={e=>{buy();}}>BUY</button>:
                        <></>
                      } 
                    </Row>
                  </Container>
                </Modal.Body>
            </Modal>
            
              {/* <Routes>
                {
                  keys.map((item)=> <Route exact path={`${item.replace(/\s/g,'')}`} element={< Child images = {obj[item]} title={item} />}></Route>)
                }
              </Routes> */}
        </div>
 )
}
