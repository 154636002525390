import './App.css';
import {useEffect, useState} from 'react'
import axios from 'axios'
import { Route, Routes} from 'react-router-dom';
import Admin from './admin';
import { useNavigate } from "react-router-dom";
import User from './user';
import Bonus from './bonus';
import Profile from './profile';
import Modal from "react-bootstrap/Modal";
import CloseButton from 'react-bootstrap/CloseButton';
import {Container,Row,Col} from "react-bootstrap"
import { ethers } from 'ethers';
import marketplaceabi from './marketabi.json' 
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import {  PaperEmbeddedWalletSdk } from '@paperxyz/embedded-wallet-service-sdk';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegUserCircle, FaSignOutAlt ,FaSearch} from "react-icons/fa";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import ReactMarkdown from 'react-markdown'

const abi = require('erc-20-abi')

export const moralis = "MvoBwk5Xv8ttWy6L7noRGROSeY0umooqhCXiGTOWSrpj9IlYWcvJpK5Iqi5mZ8Mn"

export const chain = 'mumbai'
export const centralwallet = "0xce9659d0D1821aF4b575d058dd670eff851826ce"
export const serverURL = "https://testbackend.be1stacks.com/"
export const tokenaddress = "0x35Bb76A6ea75A2ed5CB91542223a7aBC0F995D96"
export const contractaddress = "0x407ab9Ef5338A5B1fE65FDA0C54e1ed5F0dAb3De"
export const marketplacecontract = "0x353853F728328eA87761af564bEdb6dc808Cce61"
export const bonuscontract = "0x2Ae750A79663825dd5ca10fD63b4cdeEC0428ec3"
export const USDC = "0xE097d6B3100777DC31B34dC2c58fB524C2e76921"
export const DEFAULT_CHAIN_ID = 80001
export const rpc = "https://rpc-mumbai.maticvigil.com"

// export const serverURL = "https://backend.be1stacks.com/"
// export const centralwallet = "0x7758Fca408439E8953fDA96f22Bd419D4dDEc4ba"
// export const chain = 'polygon'
// export const tokenaddress = "0x842747F6c4eC88b3C76786F3974B4fE874d75506"
// export const contractaddress = "0x47980930c098b578DEd327a12cBA1255eA626C59"
// export const marketplacecontract = "0xBCDaE8c46932ac3b5E89f091DeCf9697372BB800"
// export const bonuscontract = "0xbf764de1b99Cd48504A57cE6fdbc15d024aEA93E"
// export const USDC = "0xE097d6B3100777DC31B34dC2c58fB524C2e76921"
// export const DEFAULT_CHAIN_ID = 137
// export const rpc = "https://polygon.llamarpc.com"

const Paper = new PaperEmbeddedWalletSdk({
  clientId: '1f94ed52-2fc5-4ab3-96be-b3bb677e250a',
  chain: 'Mumbai',
  styles: {
    colorBackground: '#232323',
    colorText: '#F5F5F5',
    colorPrimary: '#3E8CED',
    borderRadius: 24,
  },
});

const APP_NAME = 'Be1 checkout'
const APP_LOGO_URL = './logo.svg'

export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  darkMode: false
})

const {ethereum} = window

export const coinbaseethereum = coinbaseWallet.makeWeb3Provider(rpc, DEFAULT_CHAIN_ID)


function App() {
  const [account, setaccount] = useState()
  const [history, sethistory] = useState([])
  const [bonus, setbonus] = useState([])
  const [showa, setShowa] =useState([])
  const [show,setshow] = useState(false)
  const [showConnectModal,setshowConnectModal] = useState(false)
  const [data, setdata] = useState()
  const [owner, setowner]=useState('')
  const [id, setid]=useState()
  const [arr, setarr]=useState([])
  const [balance, setbalance]=useState()
  const [title , settitle] = useState()
  const [des , setdes] = useState()
  const [depth, setDepth] =useState()
  const [parent, setparent] = useState()
  const [username, setusername] = useState()
  const [totaldata , settotaldata] =useState([])
  const [islogedin ,setlogedin] = useState(false)
  const [signer, setsigner] = useState()
  const [wallettype, setwallettype] = useState("")


  const navigate = useNavigate();
  const handleClose = () =>{setshow(false)}
  const closeConnectModal=()=>{setshowConnectModal(false)}

  useEffect(()=>{
    if(account){
      be1balance(account)
    }else{

    }
  },[account])

  async function be1balance(account){
    let provider = new ethers.providers.JsonRpcProvider(rpc)
    let tokenContract = new ethers.Contract(tokenaddress, abi, provider);
    let allow = await tokenContract.balanceOf(account)
    setbalance( parseInt( allow.toString() ) /1000000 )
  }

  const buycard = () =>{
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer d4b4b450-d56a-42f7-b8ee-2c8e47dd614e'
      },
      body: JSON.stringify({
        sendEmailOnCreation: false,
        requireVerifiedEmail: false,
        quantity: 1,
        metadata: {},
        expiresInMinutes: 15,
        usePaperKey: false,
        hideNativeMint: false,
        hidePaperWallet: false,
        hideExternalWallet: false,
        hidePayWithCard: false,
        hideApplePayGooglePay: false,
        hidePayWithCrypto: false,
        hidePayWithIdeal: true,
        limitPerTransaction: 5,
        redirectAfterPayment: false,
        sendEmailOnTransferSucceeded: true,
        contractId: '44120603-d2e6-44da-8a8d-a8a128d0cb47',
        title: 'test',
        mintMethod: {
          name: 'swap_MATIC_to_BE1',
          args: {receiver: '$WALLET'},
          payment: {value: '0.001 * $QUANTITY', currency: 'MATIC'}
        }
      })
    };
    
    fetch('https://paper.xyz/api/2022-08-12/checkout-link-intent', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }

  const login= async ()=>{
    setshowConnectModal(false)

    await Paper.auth.loginWithPaperModal();
    const user = await Paper.getUser()
    console.log(user)

    const signer = await user.wallet.getEthersJsSigner();

    let sig = await signer.signMessage("Be1 market place sign in")
    console.log(sig)
    const options = {
      method: 'POST',
      url: `${serverURL}login`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data:{
        signature:sig,
      }
    };

    let res = await axios.request(options)
    console.log(res)
    if(res.data.message == 'Success'){
      localStorage.setItem('token', res.data.token)
    }
    let token =  localStorage.getItem('token')
    console.log(token)
    let config = {
      method: 'get',
      url: `${serverURL}get_username`,
      headers: { 
        'Content-Type': 'application/json',
        'x-access-token': token
      }
    };
    res = await axios(config)
    setusername(res.data.username)
    setlogedin(res.data.isLoggedIn)
    
    setsigner(user)
  }

  const buy = async () => {
    if(account){
      if(wallettype == "metamask"){
        let provider = new ethers.providers.Web3Provider(ethereum);
        let signer = provider.getSigner();
        let tokenContract = new ethers.Contract(tokenaddress, abi, signer);
        let allow = await tokenContract.allowance(account, marketplacecontract)
        if(parseInt(ethers.utils.formatEther(allow.toString())) < 100){
          let tx = await tokenContract.approve(marketplacecontract, ethers.utils.parseUnits("100000000000000", "ether"))
          await tx.wait()
        }
        let marketContract = new ethers.Contract(marketplacecontract, marketplaceabi, signer);
        let tx = await marketContract.buyBe1(id);
        await tx.wait()
        let config = {
          method: 'get',
          url: `${serverURL}set_data`
        };
        let res = await axios(config)
      }else if(wallettype == "coinbase"){
        let provider = new ethers.providers.Web3Provider(coinbaseethereum);
        let signer = provider.getSigner();
        let tokenContract = new ethers.Contract(tokenaddress, abi, signer);
        let allow = await tokenContract.allowance(account, marketplacecontract)
        if(parseInt(ethers.utils.formatEther(allow.toString())) < 100){
          let tx = await tokenContract.approve(marketplacecontract, ethers.utils.parseUnits("100000000000000", "ether"))
          await tx.wait()
        }
        let marketContract = new ethers.Contract(marketplacecontract, marketplaceabi, signer);
        let tx = await marketContract.buyBe1(id);
        await tx.wait()
        let config = {
          method: 'get',
          url: `${serverURL}set_data`
        };
    
        let res = await axios(config)
      }
      
    }else{
      setshowConnectModal(true)
    }
  }

  const go = (key)=>{
    let hierarchy = parseInt(JSON.parse(showa[key]['metadata']).attributes[0].value)
    let name = JSON.parse(showa[key]['metadata']).name
    let temp = setItems(hierarchy + 1, name)
    if(temp){
      let obj = {}
      obj['parent'] = name
      obj['hierarchy'] = hierarchy
      obj['image'] = 
      "https://be1stacks.myfilebase.com/ipfs/" + JSON.parse(showa[key].metadata).image.slice(7)
      //  "https://be1-images.s3.us-west-2.amazonaws.com/" +
      //                JSON.parse(showa[key].metadata).image.slice(7) + "."
      //                 + (JSON.parse(showa[key].metadata).image.slice(-3) == 'png' || JSON.parse(showa[key].metadata).image.slice(-3) == 'PNG'?'jpg':"jpg")
      sethistory([...history, obj])
      console.log(history)
      setparent(name)
      setDepth(hierarchy)
    }
  }

  const sold = (items) => {
    for(let i = 0 ; i < items.length; i ++){
      if(items[i]['owner_of'].toLowerCase() == centralwallet.toLowerCase()){
        return items[i]
      }
    }
    return items[0]
  }

  useEffect(()=>{
    if(coinbaseethereum._addresses.length != 0){
      setwallettype('coinbase')
      let provider = new ethers.providers.Web3Provider(coinbaseethereum);
      let signer = provider.getSigner();
      setsigner(signer)
      let token =  localStorage.getItem('token')
      setaccount(coinbaseethereum._addresses[0])
      let config = {
        method: 'get',
        url: `${serverURL}get_username`,
        headers: { 
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      };
  
      axios(config)
      .then(function (response) {
        setusername(response.data.username)
        setlogedin(response.data.isLoggedIn)
      })

    }else if(ethereum && ethereum.selectedAddress){
      let provider = new ethers.providers.Web3Provider(ethereum);
      let signer = provider.getSigner();
      setsigner(signer)
      setwallettype('metamask')
      ethereum.request({ method: 'eth_requestAccounts' }).then(add=>setaccount(add[0]))
      let token =  localStorage.getItem('token')
      let config = {
        method: 'get',
        url: `${serverURL}get_username`,
        headers: { 
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      };
  
      axios(config)
      .then(function (response) {
        setusername(response.data.username)
        setlogedin(response.data.isLoggedIn)
      })
    }

    Paper.getUser().then(async res=>{
      if(res.status == 'Logged In, Wallet Initialized'){
        setaccount(res.data.wallet.walletAddress)
        console.log(res.data.wallet.walletAddress)
        setwallettype("paper")
        const user = await Paper.initializeUser()
        const signer = await user.wallet.getEthersJsSigner();
        setsigner(signer)
        let token =  localStorage.getItem('token')
        let config = {
          method: 'get',
          url: `${serverURL}get_username`,
          headers: { 
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        };
    
        axios(config)
        .then(function (response) {
          setusername(response.data.username)
          setlogedin(response.data.isLoggedIn)
        })
      }
    })
    
    const options = {
      method: 'get',
      url: `${serverURL}get_all`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };

    axios(options)
      .then(function (res) {
        for(let i = 0; i < res.data.length; i ++){
          let attributes = JSON.parse(res.data[i].metadata).attributes
          if(!attributes.length == 0){
            if(attributes[0].value == '0'){
              console.log(res.data[i])
              arr.push(res.data[i])
            }
          }
        }

        settotaldata(res.data)
        arr.sort((a, b)=>{
          return parseInt(JSON.parse(a['metadata'])['attributes'][1]['value']) - parseInt(JSON.parse(b['metadata'])['attributes'][1]['value'])
        })

        let temp = []
        console.log(arr)
        temp.push(sold(arr))
        if(temp.length == 0)
        temp.push(arr[arr.length - 1])
        setShowa(temp)
        setdata(temp[0])
        setowner(temp[0].owner_of)
        setid(temp[0]['token_id'])
        setdes(JSON.parse(temp[0]['metadata'])['description'])
        
      })
  },[])

  const setItems = (depth, parent) => {
    let obj = {};
    console.log(depth, parent)

    for(let i = 0; i < totaldata.length; i ++){
      let attributes = JSON.parse(totaldata[i]['metadata']).attributes
      if(parseInt(attributes[0].value) == depth && totaldata[i]['metadata'].toUpperCase().includes(parent.toUpperCase())){
        console.log(attributes)
        if(obj[JSON.parse(totaldata[i]['metadata']).name] == undefined) {
          obj[JSON.parse(totaldata[i]['metadata']).name] = [];
          obj[JSON.parse(totaldata[i]['metadata']).name].push(totaldata[i]) 
        }
        else obj[JSON.parse(totaldata[i]['metadata']).name].push(totaldata[i])
      }
    }

    console.log(obj)

    let values = Object.values(obj)
    let keys = Object.keys(obj)
    let arr = []
    console.log(obj)
    for(let i = 0; i < keys.length; i++){
      values[i].sort((a, b)=>{
        return parseInt(JSON.parse(a['metadata'])['attributes'][1]['value']) - parseInt(JSON.parse(b['metadata'])['attributes'][1]['value'])
      })
      arr.push(sold(values[i]))
    }

    if(arr.length != 0){
      console.log(arr)
      setShowa(arr)
      return true
    }else return false
      
  }

  const connect = async () => {
    if(ethereum)
      {
        let provider;
        if(ethereum.providers)
          provider = ethereum.providers.find((provider) => provider.isMetaMask);
        else provider = ethereum
        let accounts = await provider.request({ method: 'eth_requestAccounts' });
        if(accounts){
          setaccount(accounts[0]);
          setwallettype("metamask")
          let sig = await signMSG()

          const options = {
            method: 'POST',
            url: `${serverURL}login`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data:{
              signature:sig,
              address:accounts[0]
            }
          };
      
          let res = await axios.request(options)
          if(res.data.message == 'Success'){
            localStorage.setItem('token', res.data.token)
          }
          let token =  localStorage.getItem('token')
          let config = {
            method: 'get',
            url: `${serverURL}get_username`,
            headers: { 
              'Content-Type': 'application/json',
              'x-access-token': token
            }
          };
          res = await axios(config)
          setusername(res.data.username)
          setlogedin(res.data.isLoggedIn)
          setshowConnectModal(false)
        }
      }
    else
      window.location.href='https://metamask.io/download.html'
  }

  const signMSG = async () => {
    let providear;
    if(ethereum.providers)
      providear = ethereum.providers.find((provider) => provider.isMetaMask);
    else providear = ethereum
    let provider = new ethers.providers.Web3Provider(providear);
    let signer = provider.getSigner();
    let signature = await signer.signMessage("Be1 market place sign in")
    setsigner(signer)
    return signature
  }

  const signMSGcoinbase = async () => {
    let provider = new ethers.providers.Web3Provider(coinbaseethereum);
    let signer = provider.getSigner();
    let signature = await signer.signMessage("Be1 market place sign in")
    setsigner(signer)
    return signature
  }

  const goBonus = () =>{
    navigate('/bonus')
  }
  
  const mynft = ()=>{
      navigate('/mynft')
  }

  const back = (key) => {
    let parent = history[key].parent
    let depth = history[key].hierarchy
   
    if(depth >= 1){
      setItems(depth, parent)
      setDepth(depth - 1)
      if(depth == 0){
        setparent(JSON.parse(showa[0]['metadata']).attributes[1].value)
        console.log(JSON.parse(showa[0]['metadata']).attributes[1].value)
      }
      
      if(depth >= 1){
        setparent(JSON.parse(showa[0]['metadata']).attributes[2 + depth].value)
        console.log(JSON.parse(showa[0]['metadata']).attributes[2 + depth].value)
      }
      
    }else{
      setItems(0, "Be1")
    }
  } 

  const showpopup = (key) =>{
    setdes(JSON.parse(showa[key]['metadata'])['description'])
    settitle(JSON.parse(showa[key]['metadata'])['name'])
    setid(parseInt(showa[key]['token_id']))
    setowner(showa[key]['owner_of'])
    setdata(showa[key])
    // setshow(true)
  }

  const transactoin = ()=>{
    window.open(`https://mumbai.polygonscan.com/address/${account}#tokentxns`)
  }

  const goanalytics=()=>{
    window.open(`https://leaderboard.be1stacks.com`)
  }

  const goleaderboard=()=>{
    window.open('https://leaderboard.be1stacks.com/items')
  }

  const onDirection = (direction, pressed) => {
    console.log(direction, pressed)
  }

  const logout=()=>{
    localStorage.removeItem("token")
    window.location.reload(false);
  }

  const coinbaseconnect = async () =>{
    const accounts = await coinbaseethereum.request({ method: 'eth_requestAccounts' })
    let sig = await signMSGcoinbase()
    if(accounts){
      setaccount(accounts[0])
      setwallettype("coinbase")
      const options = {
        method: 'POST',
        url: `${serverURL}login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data:{
          signature:sig,
          address:accounts[0]
        }
      };
  
      let res = await axios.request(options)
      if(res.data.message == 'Success'){
        localStorage.setItem('token', res.data.token)
      }
      let token =  localStorage.getItem('token')
      let config = {
        method: 'get',
        url: `${serverURL}get_username`,
        headers: { 
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      };
      res = await axios(config)
      setusername(res.data.username)
      setlogedin(res.data.isLoggedIn)
      setshowConnectModal(false)
    }
  }


   return (
     <div className="App">
      <header className="App-header">
        <div className='row w-100 position-absolute connectbutton zindex'>
          {islogedin?
          <div className='col-1 col-md-1' >
            <Dropdown>
            <Dropdown.Toggle style={{borderColor:"#8f8a8a",backgroundColor:"transparent",borderRadius:'50%',width:"45px",height:"45px",display:'flex',justifyContent:"center",padding:'0px'}} id="dropdown-basic">
              <img src="./BE.gif" className='userimage'></img>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={e=>navigate('/profile')} ><FaRegUserCircle/> &nbsp; My Profile</Dropdown.Item>
              <Dropdown.Item onClick={e=>mynft()}><FaSearch/> &nbsp; Search</Dropdown.Item>
              <Dropdown.Item onClick={e=>logout()}><FaSignOutAlt/> &nbsp; Logout</Dropdown.Item>
            </Dropdown.Menu>
              
            </Dropdown>
            </div>:
            <div className='button col-2 col-md-2' onClick={e=>{setshowConnectModal(true)}}>Connect</div>}
         <div className='mt-2 col-2 col-md-2'> Be1: {account? balance : "0"} </div>
          <div className='button col-2 col-md-2' onClick={e=>{goanalytics()}}>Analytic</div>
          <div className='button col-2 col-md-2' onClick={e=>{goleaderboard()}}>LeaderBoard</div>
          <div className='button col-2 col-md-2' onClick={e=>goBonus()}>Bonus</div>

        </div>
        <hr className='line'></hr>
            <Modal 
              dialogClassName ="modala"
              show={showConnectModal}
              onHide={closeConnectModal}
              backdrop="static"
              keyboard={false}
              centered>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={11}>

                      </Col>
                      <Col md={1}>
                        <CloseButton onClick={closeConnectModal}/>
                      </Col>
                    </Row>
                    <Row>
                      <h2 className='text-center'>Connect</h2>
                    </Row>
                    <Row>
                      <h5 className='text-center'>Choose a wallet connection method.</h5>
                    </Row>
                    <br/>
                    <Row onClick={e=>connect()} className='walletconnecttopbutton'>
                      <Col xs={6} md={3} className='d-flex justify-content-center'>
                        <img src='./metamask.svg' className=' smallicon'></img>
                      </Col>
                      <Col xs={6} md={7}>
                        <h4 className='text-center mb-0 pt-2'>MetaMask</h4>
                      </Col>
                      <Col xs={6} md={2} className='d-flex justify-content-center'>
                      </Col>
                    </Row>
                    <Row onClick={e=>coinbaseconnect()} className='walletconnecttopbutton'>
                      <Col xs={6} md={3} className='d-flex justify-content-center'>
                        <img src='./coinbase-logo.svg' className=' smallicon'></img>
                      </Col>
                      <Col xs={6} md={7}>
                        <h4 className='text-center mb-0 pt-2'>CoinBase</h4>
                      </Col>
                      <Col xs={6} md={2} className='d-flex justify-content-center'>
                      </Col>
                    </Row>
                    <Row onClick={e=>{login()}} className='walletconnectbotttombutton'>
                      <Col xs={6} md={3} className='d-flex justify-content-center'>
                        <img src='./paper.svg' className=' smallicon'></img>
                      </Col>
                      <Col xs={6} md={7}>
                        <h4 className='text-center mb-0 pt-2'>Paper</h4>
                      </Col>
                      <Col xs={6} md={2} className='d-flex justify-content-center'>
                      </Col>
                    </Row>

                  </Container>
                </Modal.Body>
            </Modal>
          <Routes>
            <Route exact path="/" element={
              <SplitterLayout percentage={true} primaryMinSize={70}>
                <div className="App-header1">
                  <div className='historybar'>
                    {
                      history.length == 0?
                        <></>:
                        history.map((item, key) =>
                          <div onClick={e => back(key)} className='historycard' key={key}>
                            <img className='historycard' src={item.image}></img>
                          </div>
                        )
                    }
                  </div>
                  <br/>
                  <div className='d-flex justify-content-center flex-wrap'>        
                      {showa.length == 0 ?
                      <div className="">No countries</div>:
                      showa.map((image, key)=><div>
                      <div className='imagecard' key={key}>
                      {
                          image?<div>
                            <img className='image' onClick={e=>{showpopup(key);}} src= {
                               "https://be1stacks.myfilebase.com/ipfs/" + JSON.parse(image.metadata).image.slice(7)
                              // "https://be1-images.s3.us-west-2.amazonaws.com/" + JSON.parse(image.metadata).image.slice(7) + "." + (JSON.parse(image.metadata).image.slice(-3) == 'png' || JSON.parse(image.metadata).image.slice(-3) == 'PNG'?'jpg':"jpg")
                            }
                            ></img>
                            <div className='d-flex flex-wrap'>
                              <div className='round'>
                                {JSON.parse(image['metadata'])['attributes'][2]['value']}
                              </div>
                              <div className='round'>
                                {Math.pow(3, parseInt(JSON.parse(image['metadata'])['attributes'][2]['value'])-1) }
                              </div>
                              <div className='round'>
                                {image.sell?"S":"N"}
                              </div>
                              <div onClick={e=>go(key)} className='roundedbutton'>
                                +
                              </div>
                            </div>
                            </div>:
                          <img className='image'></img>
                        }
                        
                      </div>
                    </div>)
                      }
                </div>
                </div>
                <div >
                <Container>
                  <br/>
                  <Row>
                      <Col xs={10} md={10}>
                      { data?
                        <img className='w-100' src= {
                          "https://be1stacks.myfilebase.com/ipfs/" + JSON.parse(data.metadata).image.slice(7)
                          // "https://be1-images.s3.us-west-2.amazonaws.com/" + JSON.parse(data.metadata).image.slice(7) + "." +  (JSON.parse(data.metadata).image.slice(-3) == 'png' || JSON.parse(data.metadata).image.slice(-3) == 'PNG' || JSON.parse(data.metadata).image.slice(-3) == 'PNG' ?'jpg':"jpg")
                          }></img> : <></>}
                      </Col>
                    </Row>
                  { data?
                        JSON.parse(data['metadata'])['attributes'].map(item=>
                        <Row>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['trait_type']}
                            </div>
                            </Col>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['value']}
                            </div>
                            </Col>
                        </Row>
                      ):""}
                       <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Description
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        <ReactMarkdown>{des}</ReactMarkdown>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Owner
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                      {owner.slice(0, 5) +"..."+ owner.slice(owner.length - 5, owner.length)}
                      </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Id
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {id}
                      </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={1} md={1}></Col>
                    <Col xs={5} md={5}>{
                        owner.toUpperCase() == centralwallet.toUpperCase()?
                        <button class="btn btn-outline-primary btn-md btn-block buttonbox" onClick={e=>{buy();}}>BUY</button>:
                        <></>
                      } </Col>
                    <Col xs={5} md={5}>
                      {
                        owner.toUpperCase() == centralwallet.toUpperCase()?
                        <button class="btn btn-outline-primary btn-md btn-block buttonbox" onClick={e=>{buycard();}}>BUY(Card)</button>:
                        <></>
                      }
                    </Col>

                    <Col xs={1} md={1}></Col>
                      
                    </Row>
                  </Container>
                </div>
              </SplitterLayout>}>
            </Route>
            <Route exact path="bonus/" element={< Bonus images = {bonus} title={"Bonus"} />}></Route>
            <Route exact path="mynft/" element={< User account = {account} />}></Route>
            <Route exact path="profile/" element={< Profile account = {account} signer = {signer} wallettype={wallettype}/>}></Route>
            <Route exact path="admin/" element={< Admin account = {account} />}></Route>
          </Routes>  
      </header>
    </div>
    // </PaperSDKProvider>
  );
}

export default App;
