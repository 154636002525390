import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { contractaddress, marketplacecontract, DEFAULT_CHAIN_ID, rpc } from './App';
import { ethers } from 'ethers';
import NFTabi from './abi.json'
import marketplaceabi from './marketabi.json' 
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'

const coinbaseWallet = new CoinbaseWalletSDK({
    appName: "APP_NAME",
    appLogoUrl: "APP_LOGO_URL",
    darkMode: false
  })

export default function Admin(account) {
    const coinbaseethereum = coinbaseWallet.makeWeb3Provider(rpc, DEFAULT_CHAIN_ID)
    const [ids, setIds] = useState()
    const List = async () => {
        if(!ids || ids == "" ) {alert("input ids"); return;}
        let provider = new ethers.providers.Web3Provider(coinbaseethereum);
        let signer = provider.getSigner();
        let nftContract = new ethers.Contract(contractaddress, NFTabi.abi, signer);
        console.log(coinbaseethereum._addresses[0])
        let approval = await nftContract.isApprovedForAll(coinbaseethereum._addresses[0], marketplacecontract)
        if(!approval){
            let tx = await nftContract.setApprovalForAll(marketplacecontract, true)
            await tx.wait()
        }
        let marketplaceContract = new ethers.Contract(marketplacecontract, marketplaceabi, signer)
        await marketplaceContract.batchlist(ids.split(','))
    }
    return(
        <div className='App-header1'>
            <TextField InputLabelProps={{ shrink: true }} value={ids} onChange={e=>setIds(e.target.value)} style={{marginBottom:"5px", marginRight:"5px",width:"400px"}} id="outlined-basic" label="Ids" variant="outlined" />
            <Button variant="contained" onClick={e=>{List()}}>List</Button>
        </div>
    )
}