import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios'
import { moralis , chain, contractaddress, centralwallet} from "./App";
import Modal from "react-bootstrap/Modal";
import {Container,Row,Col} from "react-bootstrap"
import TextField from '@mui/material/TextField';

export default function User(account){
    const [showaccount, setaccount] = useState(account.account)
    const [images, setimages] = useState([])
    const [title , settitle] = useState()
    const [des , setdes] = useState()
    const [show,setshow] = useState(false)
    const [data, setdata] = useState()
    const [owner, setowner]=useState('')
    const [id, setid]=useState()
    const navigate = useNavigate();
    const handleClose=()=>{setshow(false)}

    useEffect(()=>{
        if(!showaccount){
            navigate('/')
        }else{
            getNFTs(showaccount)
        }
    },[])

    useEffect(()=>{
        getNFTs(showaccount)
    },[showaccount])

    const showpopup = (key) =>{
        setdes(JSON.parse(images[key]['metadata'])['description'])
        settitle(JSON.parse(images[key]['metadata'])['name'])
        setid(parseInt(images[key]['token_id']))
        setowner(images[key]['owner_of'])
        setdata(images[key])
        setshow(true)
    }


    const getNFTs = async (account)=>{
        const options = {
            method: 'GET',
            url: `https://deep-index.moralis.io/api/v2/${showaccount}/nft`,
            params: {
                chain: chain,
                format: 'decimal',
                token_addresses: contractaddress,
                normalizeMetadata: 'false'
              },
            headers: {accept: 'application/json', 'X-API-Key': moralis}
          };
          
          let res = await axios.request(options)
          setimages(res.data.result)
    }
    const back = () => {
      navigate("/");
  }
    return(
        <div className="App-header2">
            {/* <div className="acccountText">
                {showaccount? showaccount.slice(0, 5) +"..."+ showaccount.slice(showaccount.length - 5, showaccount.length):<></>}
            </div> */}
            <div className='button' onClick={back}>back</div>
            <div className="acccountText">
                <TextField value={showaccount} style={{width:"450px"}} onChange={e=>setaccount(e.target.value)} id="outlined-basic" label="Search Address" variant="outlined" />
            </div>
            <div className='d-flex justify-content-center flex-wrap'>
                {images.length == 0 ?
                <div className=""></div>:
                images.map((image, key)=><div>
                <div className='imagecard' key={key}>
                {
                        image?<div>
                        <img className='image' onClick={e=>{showpopup(key);}} src= {
                        JSON.parse(image['metadata'])['image'].includes('pinata')
                        ?JSON.parse(image['metadata'])['image'].replace('gateway.pinata', 'be1.mypinata')
                        :"https://ipfs.io/ipfs/" + JSON.parse(image['metadata'])['image'].slice(7)
                        }></img>
                        <div className='d-flex flex-wrap'>
                            <div className='round'>
                            {JSON.parse(image['metadata'])['attributes'][0]['value']}
                            </div>
                            <div className='round'>
                            {Math.pow(3, parseInt(JSON.parse(image['metadata'])['attributes'][0]['value'])-1) }
                            </div>
                            <div className='round'>
                            {owner.toUpperCase() == centralwallet.toUpperCase()?"S":"N"}
                            </div>
                            <div className='roundedbutton'>
                            +
                            </div>
                        </div>
                        </div>:
                        <img className='image'></img>
                    }
                
                </div>
            </div>)
                }
            </div>
            <Modal 
              dialogClassName ="modala"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                  { data?
                        JSON.parse(data['metadata'])['attributes'].map(item=>
                        <Row>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['trait_type']}
                            </div>
                            </Col>
                            <Col xs={6} md={6}>
                            <div className="buttonbox">
                                {item['value']}
                            </div>
                            </Col>
                        </Row>
                      ):""}
                       <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Description
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {des}
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Owner
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {owner}
                      </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} md={3}>
                        <div className="buttonbox">
                          Id
                        </div>
                      </Col>
                      <Col xs={6} md={9}>
                      <div className="buttonbox">
                        {id}
                      </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
            </Modal>
            
        </div>
    )
}

