import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios'
import {centralwallet} from "./App";
import Modal from "react-bootstrap/Modal";
import {Container,Row,Col} from "react-bootstrap"
import TextField from '@mui/material/TextField';
import { serverURL } from "./App";
import { FaEdit, FaSignOutAlt } from "react-icons/fa";
import CloseButton from 'react-bootstrap/CloseButton';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import { ethers } from 'ethers';
import { tokenaddress, marketplacecontract } from "./App";
import CircularProgress from '@mui/material/CircularProgress';

const abi = require('erc-20-abi')

const {ethereum} = window


export default function Profile(data){
    const [showaccount, setaccount] = useState(data.account)
    const [images, setimages] = useState([])
    const [show,setshow] = useState(false)
    const [autoPilotON, setautoPilotON] = useState(false)
    const [owner, setowner]=useState('')
    const [username, setusername] = useState()
    const [newusername, setnewusername] = useState()
    const [alert, showalert] = useState(false)
    const [sucess, showsucess] = useState(false)
    const [alertmsg, setalertmsg] = useState()
    const navigate = useNavigate();
    const [edit, showedit] = useState(false)
    const handleClose=()=>{setshow(false)}
    const closeEdit = () =>{showedit(false)}

    useEffect(()=>{
        console.log(data)
        let token =  localStorage.getItem('token')
        let config = {
            method: 'get',
            url: `${serverURL}get_username`,
            headers: { 
            'Content-Type': 'application/json',
            'x-access-token': token
            }
        };
    
        axios(config)
        .then(function (response) {
            if(!response.data.isLoggedIn) back()
            setusername(response.data.username)
            setaccount(response.data.walletaddress)
        })
        
        let config1 = {
            method: 'get',
            url: `${serverURL}get_autopilot_statu`,
            headers: { 
            'Content-Type': 'application/json',
            'x-access-token': token
            }
        };

        axios(config1)
        .then(function (response) {
            setautoPilotON(response.data.status)
        })
    },[])

    useEffect(()=>{
        getNFTs(showaccount)
    },[showaccount])

    const showpopup = (key) =>{
     
    }

    const getNFTs = async (account)=>{
          let config = {
            method: 'POST',
            url: `${serverURL}get_userdata`,
            data:{
                address: account
            }
          };
            axios(config).then(res=>{
                setimages(res.data)
            })
    }

    const back = () => {
      navigate("/");
    }

    const changeusername = () =>{
        if(!newusername) {
            setalertmsg("Please input new Username")
            showalert(true)
            setTimeout(function() { showalert(false) }, 3000)
        }else{
            let token =  localStorage.getItem('token')
            let config = {
                method: 'POST',
                url: `${serverURL}set_username`,
                headers: { 
                  'Content-Type': 'application/json',
                  'x-access-token': token
                },
                data:{
                    username: newusername
                }
              };
            axios(config).then(res=>{
                if(res.data.message != "Success") {
                    setalertmsg(res.data.message)
                    showalert(true)
                    setTimeout(function() { showalert(false) }, 3000)
                }else{
                    localStorage.setItem('token', res.data.token)
                    showsucess(true)
                    setusername(newusername)
                    setnewusername(undefined)
                }
            })
        }
    }

    const transactoin = ()=>{
        window.open(`https://mumbai.polygonscan.com/address/${showaccount}#tokentxns`)
    }

    const autopilot = async () =>{
        const response = await window.confirm("Are you sure you want to do that?");
        if(response){
            if(!autoPilotON){
                setshow(true)
                console.log(data)
                if(data.wallettype == "metamask" || data.wallettype == "coinbase"){
                    let tokenContract = new ethers.Contract(tokenaddress, abi, data.signer);
                    console.log( data.signer)
                    try{
                        let allow = await tokenContract.allowance(showaccount, marketplacecontract)
                        console.log(parseInt(ethers.utils.formatEther(allow.toString())))
                        if(parseInt(ethers.utils.formatEther(allow.toString())) < 100000){
                            let tx  = await tokenContract.approve(marketplacecontract, ethers.utils.parseUnits("10000000", "ether"))
                            await tx.wait()
                            console.log(tx)
                        }
                        let token =  localStorage.getItem('token')
                        let config = {
                            method: 'POST',
                            url: `${serverURL}set_autopilot_statu`,
                            headers: { 
                            'Content-Type': 'application/json',
                            'x-access-token': token
                            },
                            data:{
                                status: !autoPilotON
                            }
                        };
            
                        axios(config).then(res=>{
                            if(res.data.message == "Success") {
                                setautoPilotON(!autoPilotON)
                            }else{
                                console.log(res)
                            }
                        })
                    }catch(e){
                        if(e) setshow(false)
                    }

                }else{
                    let temp = await  data.signer.wallet.gasless.callContract({
                        contractAddress: tokenaddress,
                        methodInterface:"function approve(address to, uint256 amount) external",
                        methodArgs:[marketplacecontract, ethers.utils.parseUnits("10000000", "ether")]
                    })
                    setshow(false)
                    let token =  localStorage.getItem('token')
                    let config = {
                        method: 'POST',
                        url: `${serverURL}set_autopilot_statu`,
                        headers: { 
                        'Content-Type': 'application/json',
                        'x-access-token': token
                        },
                        data:{
                            status: !autoPilotON
                        }
                    };
        
                    axios(config).then(res=>{
                        if(res.data.message == "Success") {
                            setautoPilotON(!autoPilotON)
                        }else{
                            console.log(res)
                        }
                    })
                }
            }
        }
        
    }

    return(
        <div className="">
            
            {/* <div className='button' onClick={back}>back</div> */}
            <Row className="ptop75">
                <Col xs={1} md={1}>

                </Col>
                <Col xs={10} md={10}  className="d-flex">
                    <h2 className="text-left">{username}</h2>
                    &nbsp;&nbsp;
                    <div className="editicon" onClick={showedit}><FaEdit/></div>
                </Col>
                <Col xs={1} md={1}>

                </Col>
            </Row>
            <Row className="">
                <Col xs={1} md={1}>

                </Col>
                <Col xs={10} md={10}  className="d-flex">
                    <div className="text-left">{showaccount}</div>
                    &nbsp;
                    <div  className="walleticon" onClick={transactoin}><FaSignOutAlt/></div>
                </Col>
                <Col xs={1} md={1}>

                </Col>
            </Row>
            <Row className="">
                <Col xs={1} md={1}>

                </Col>
                <Col xs={10} md={10}  className="d-flex">
                    <h5 className="mt-1 text-left">AutoPilot Mode</h5> <Switch checked={autoPilotON} onChange={e=>autopilot(true)}/>
                </Col>
                <Col xs={1} md={1}>

                </Col>
                
            </Row>
            <div className='d-flex justify-content-center flex-wrap'>
                {images.length == 0 ?
                <div className=""></div>:
                images.map((image, key)=><div>
                <div className='imagecard' key={key}>
                {
                        image?<div>
                        <img className='image' onClick={e=>{showpopup(key);}} src= {
                        "https://be1-images.s3.amazonaws.com/" + image.token_id + "." + (JSON.parse(image.metadata).image.slice(-3) == 'png' || JSON.parse(image.metadata).image.slice(-3) == 'PNG'?'jpg':"jpg")
                        }></img>
                        <div className='d-flex flex-wrap'>
                            <div className='round'>
                            {JSON.parse(image['metadata'])['attributes'][0]['value']}
                            </div>
                            <div className='round'>
                            {Math.pow(3, parseInt(JSON.parse(image['metadata'])['attributes'][0]['value'])-1) }
                            </div>
                            <div className='round'>
                            {owner.toUpperCase() == centralwallet.toUpperCase()?"S":"N"}
                            </div>
                            <div className='roundedbutton'>
                            +
                            </div>
                        </div>
                        </div>:
                        <img className='image'></img>
                    }
                
                </div>
            </div>)
                }
            </div>
            <Modal 
              dialogClassName ="modala"
              show={edit}
              onHide={closeEdit}
              backdrop="static"
              keyboard={false}
              centered>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={11}>

                      </Col>
                      <Col md={1}>
                        <CloseButton onClick={closeEdit}/>
                      </Col>
                    </Row>
                    <Row>
                      <h2 className='text-center'>Edit Your Username</h2>
                    </Row>
                        <br/>
                            {alert ? <Alert severity="error">{alertmsg}</Alert>:<></>}
                            {sucess?<Alert severity="success">Success!</Alert>:<></>}
                        <br/>
                    <Row>
                        <TextField value={newusername} onChange={e=>setnewusername(e.target.value)} id="outlined-basic" label="New Username" variant="outlined" />
                    </Row>
                        <br/>
                    <Row>
                        <Button onClick={changeusername} variant="outlined">Submit</Button>
                    </Row>
                    
                  </Container>
                </Modal.Body>
            </Modal>
            <Modal 
              dialogClassName ="modala"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={11}>

                      </Col>
                      <Col md={1}>
                        <CloseButton onClick={handleClose}/>
                      </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col xs={6} md={10}>
                            <h2 className='text-center'>Approve Token </h2>
                        </Col>
                        <Col md={2}>
                            <div><CircularProgress /></div>
                        </Col>
                    </Row>
                        <br/>
                    
                  </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}